import styles from "./TextGrid.module.css";
import { H3 } from "../titles/titles";

export default function TextGrid({ content, className }) {
  let appliedClasses = `${styles.grid}`;
  if (className) appliedClasses += ` ${className}`;

  return (
    <div className={appliedClasses}>
      {content?.map((textAndTitle, index1) => {
        return (
          <div key={index1}>
            <H3>{textAndTitle.title}</H3>
            {textAndTitle.text?.map((paragraph, index2) => {
              if (typeof paragraph === "string")
                return <p key={`${index1}${index2}`}>{paragraph}</p>;
              const p = paragraph?.map((textPart, index3) => (
                <span key={`${index3}`}>{textPart}</span>
              ));
              return <p key={`${index1}${index2}`}>{p}</p>;
            })}
          </div>
        );
      })}
    </div>
  );
}
