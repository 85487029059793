import styles from "./Link.module.css";
import { Link } from "react-router-dom";

export default function RouterLink({ children, route }) {
  return (
    <Link className={styles.Link} to={route}>
      {children}
    </Link>
  );
}
