import styles from "./SectionSkills.module.css";
import { ReactComponent as CssIcon } from "../../../static/icons/css.svg";
import { ReactComponent as HtmlIcon } from "../../../static/icons/html.svg";
import { ReactComponent as JsIcon } from "../../../static/icons/js.svg";
import { ReactComponent as ReactIcon } from "../../../static/icons/react.svg";
import { ReactComponent as GitIcon } from "../../../static/icons/git.svg";
import { ReactComponent as NpmIcon } from "../../../static/icons/npm.svg";
import { ReactComponent as LinuxIcon } from "../../../static/icons/linux.svg";
import { ReactComponent as SqliteIcon } from "../../../static/icons/sqlite.svg";
import { Section, Centered } from "../../general/layout/layout";

export default function SectionSkills() {
  return (
    <Section gradient id="technical_skills">
      <Centered>
        <h2 className={styles.title}>Technical Skills</h2>
        <div className={styles.grid}>
          <div className={styles.icon}>
            <CssIcon title="css3" />
          </div>
          <div className={styles.icon}>
            <HtmlIcon title="html5" />
          </div>
          <div className={styles.icon}>
            <JsIcon title="JavaScript" />
          </div>
          <div className={styles.icon}>
            <ReactIcon title="React" />
          </div>
          <div className={styles.icon}>
            <GitIcon title="Git" />
          </div>
          <div className={styles.icon}>
            <NpmIcon title="Npm" />
          </div>
          <div className={styles.icon}>
            <LinuxIcon title="Linux" />
          </div>
          <div className={styles.icon}>
            <SqliteIcon title="Sqlite" />
          </div>
        </div>
      </Centered>
    </Section>
  );
}
