import styles from "./CaptureMobileAndDesktopContainer.module.css";

export default function CaptureMobileAndDesktopContainer({ source, toRight, toLeft }) {
  if (toRight) {
    return (
      <div className={styles.CapturesContainer}>
        <img
          className={styles.capturesDesktop}
          src={source.desktop}
          alt="screen capture of the desktop version"
        />
        <img
          className={styles.capturesMobile}
          src={source.mobile}
          alt="screen capture of the mobile version"
        />
      </div>
    );
  }
  if (toLeft) {
    return (
      <div className={styles.CapturesContainer}>
        <img
          className={styles.capturesMobile}
          src={source.mobile}
          alt="screen capture of the mobile version"
        />
        <img
          className={styles.capturesDesktop}
          src={source.desktop}
          alt="screen capture of the desktop version"
        />
      </div>
    );
  }
}
