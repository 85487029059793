import TextGrid from "../TextGrid/TextGrid";
import ProblemsToughts from "../ProblemsThoughts/ProblemsThoughts";

export default function ProblemsThoughtJm({ content }) {
  return (
    <ProblemsToughts>
      <TextGrid content={content} />
    </ProblemsToughts>
  );
}
