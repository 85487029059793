import HomepageSectionHeader from "../HomepageSectionHeader/HomepageSectionHeader";
import StandardLink from "../../general/Link/StandardLink";
import { Section, Centered } from "../../general/layout/layout";

export default function SectionContact() {
  const titleSpan = "contact";
  const title = "Let's get in touch!";
  const text =
    "My Inbox is always open, so feel free to reach out if you are looking for a developer, have a question or just want to say hi!";

  return (
    <Section gradientDown id={"contact"}>
      <Centered>
        <HomepageSectionHeader titleSpan={titleSpan} title={title} text={text} />
        <StandardLink href="mailto:schwaederle.jerome@gmail.com">
          schwaederle.jerome@gmail.com
        </StandardLink>
      </Centered>
    </Section>
  );
}
