import styles from "./ProjectCard.module.css";
import RouterLink from "../../general/Link/RouterLink";

export default function ProjectCard({ title, text, url, route }) {
  return (
    <div className={styles.container}>
      <h3>{title}</h3>
      <div className={styles.card}>
        <div
          className={`${styles.cardSide} ${styles.cardSideFront} ${
            styles[`cardSideFront--${url}`]
          }`}
        ></div>
        <div className={`${styles.cardSide} ${styles.cardSideBack}`}>
          <p>{text}</p>
          <RouterLink route={route}>See Project Details</RouterLink>
        </div>
      </div>
    </div>
  );
}
