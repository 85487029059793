import { H2 } from "../titles/titles";
import { Section, Centered } from "../../general/layout/layout";
import TextGrid from "../TextGrid/TextGrid";

export default function WhyBuildProject({ content }) {
  return (
    <Section>
      <Centered>
        <H2>Why build this project</H2>
        <TextGrid content={content} />
      </Centered>
    </Section>
  );
}
