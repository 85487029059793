import CaptureContainer from "../CaptureMobileAndDesktopContainer/CaptureMobileAndDesktopContainer";
import passphraseV0 from "../../../static/project-captures/passphrase/version0.webp";
import passphraseV1 from "../../../static/project-captures/passphrase/version1.webp";
import passphraseV1Mobile from "../../../static/project-captures/passphrase/version1-mobile.webp";
import passphraseV0Mobile from "../../../static/project-captures/passphrase/version0-mobile.webp";

import DesignsSection from "../DesignsSection/DesignsSection";

const sourcesV0 = { desktop: passphraseV0, mobile: passphraseV0Mobile };
const sourcesV1 = { desktop: passphraseV1, mobile: passphraseV1Mobile };

export default function DesignsPassphrase() {
  return (
    <DesignsSection title={"Design Improvements"}>
      <p>You can see the evolution of the design after I decided to make this project public!</p>
      <CaptureContainer source={sourcesV0} toRight />
      <CaptureContainer source={sourcesV1} toRight />
    </DesignsSection>
  );
}
