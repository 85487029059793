import styles from "./Link.module.css";
import { Link } from "react-router-dom";
import logo from "../../../static/logo/logoWhiteOnBlack.webp";
import routes from "../../../routes/routes";

export default function RouterLink({ className }) {
  let appliedClasses = `${styles.homePageLink}`;
  if (className) appliedClasses += ` ${className}`;

  return (
    <Link className={appliedClasses} to={routes.index}>
      <img src={logo} alt="Logo" />
    </Link>
  );
}
