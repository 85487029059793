import styles from "./WhatILearned.module.css";
import { H2 } from "../titles/titles";
import { ReactComponent as BulbIcon } from "../../../static/icons/bulb.svg";
import { Centered, Section } from "../../general/layout/layout";

export default function WhatILearned({ content }) {
  return (
    <Section gradient>
      <Centered className={styles.lessonsLearned}>
        <H2 className={styles.headingSecondary}>What I learned</H2>
        <ul className={styles.list}>
          {content.map((paraphraph, index) => (
            <li key={index} className={styles.listItem}>
              <BulbIcon />
              <p className={styles.text}>{paraphraph}</p>
            </li>
          ))}
        </ul>
      </Centered>
    </Section>
  );
}
