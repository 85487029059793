import styles from "./layout.module.css";

export function Section({ className, children, gradient, gradientDown, id }) {
  let appliedStyles = styles.layout;
  if (className) appliedStyles += ` ${className}`;
  if (gradient) appliedStyles += ` ${styles.gradientToRight}`;
  if (gradientDown) appliedStyles += ` ${styles.gradientToBottom}`;
  if (id)
    return (
      <section id={id} className={appliedStyles}>
        {children}
      </section>
    );
  return <section className={appliedStyles}>{children}</section>;
}

export function Centered({ className, children, captures }) {
  let appliedStyles = styles.content;
  if (captures) appliedStyles = ` ${styles.contentCaptures}`;
  if (className) appliedStyles += ` ${className}`;

  return <div className={appliedStyles}>{children}</div>;
}
