import styles from "./HeaderGrid.module.css";
import TextLink from "../../../components/general/Link/TextLink";
import { H3 } from "../titles/titles";

export default function HeaderGrid({ content }) {
  const { coreFonctionalities, technologiesUsed, codeLink, siteLink } = content;

  return (
    <div className={styles.headerGrid}>
      <div className={styles.resumeItem}>
        <H3>Core functionalities</H3>
        <ul className={styles.headerList}>
          {coreFonctionalities?.map((fonctionality, index) => (
            <li key={index}>{fonctionality}</li>
          ))}
        </ul>
      </div>
      <div className={styles.resumeItem}>
        <H3>Technologies used</H3>
        <ul className={styles.headerList}>
          {technologiesUsed?.map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}
        </ul>
      </div>
      <div className={styles.resumeItem}>
        <H3>Code</H3>
        <TextLink href={codeLink}>Github</TextLink>
      </div>
      <div className={styles.resumeItem}>
        <H3>Live Demo</H3>
        <TextLink href={siteLink}>Site</TextLink>
      </div>
    </div>
  );
}
