import DesignsSection from "../DesignsSection/DesignsSection";
import capture1 from "../../../static/project-captures/flags/1.webp";
import capture3 from "../../../static/project-captures/flags/3.webp";
import capture4 from "../../../static/project-captures/flags/4.webp";
import capture6 from "../../../static/project-captures/flags/6.webp";
import { H3 } from "../titles/titles";
import CaptureRowWrap from "../CaptureRowWrap/CaptureRowWrap";

const captures = [capture1, capture3, capture4, capture6];

export default function DesignsFlags() {
  return (
    <DesignsSection title="Responsive Design with CSS Modules">
      <H3>Below is a frieze where you can see the different steps of media queries</H3>
      <p>It has been designed as a desktop first application</p>
      <CaptureRowWrap captures={captures} />
    </DesignsSection>
  );
}
