import routes from "../../../routes/routes";

const projects = [
  {
    title: "Passphrase Generator",
    text: "You need a truly random passphrase generator? Here it is!",
    url: "1",
    route: routes.passphrase,
  },
  {
    title: "Flags",
    text: '"Flags" is a game that lets you improve your knowledge of the flags of all countries and have fun at the same time.',
    url: "2",
    route: routes.flags,
  },
  {
    title: "Majority Judgment",
    text: "This app brings the majority judgment voting system to life.",
    url: "3",
    route: routes.jm,
  },
];

export function theProjects(whichOne) {
  let whatToReturn = [];
  if (whichOne === "all") return projects;
  if (whichOne.includes("jm")) whatToReturn = [...whatToReturn, projects[2]];
  if (whichOne.includes("flags")) whatToReturn = [...whatToReturn, projects[1]];
  if (whichOne.includes("passphrase")) whatToReturn = [...whatToReturn, projects[0]];
  return whatToReturn;
}
