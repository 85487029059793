import styles from "./404.module.css";
import RouterLink from "../components/general/Link/RouterLink";
import routes from "../routes/routes";

export default function error404() {
  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <h1>That page doesn't exist!</h1>
        <h2>Sorry, the page you were looking for could not be found.</h2>
        <RouterLink route={routes.index}>Go back home</RouterLink>
      </div>
    </main>
  );
}
