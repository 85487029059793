import { H2 } from "../titles/titles";
import { Section, Centered } from "../../general/layout/layout";

export default function ProblemsTought({ children }) {
  return (
    <Section>
      <Centered>
        <H2>Problems and thought process</H2>
        {children}
      </Centered>
    </Section>
  );
}
