import TextGrid from "../TextGrid/TextGrid";
import ProblemsToughts from "../ProblemsThoughts/ProblemsThoughts";
import mdnWarning from "../../../static/project-captures/passphrase/mdnWarning.webp";
import styles from "./ProblemsThoughtsPassphrase.module.css";

export default function ProblemsToughtPassphrase({ content }) {
  return (
    <ProblemsToughts>
      <div className={styles.CapturesContainer}>
        <img
          className={styles.captureMDN}
          src={mdnWarning}
          alt="Math.random() does not provide cryptographically secure random numbers. Do not use them for anything related to security. Use the Web Crypto API instead, and more precisely the window.crypto.getRandomValues() method."
        />
      </div>
      <TextGrid content={content} className={styles.grid} />
    </ProblemsToughts>
  );
}
