import styles from "./SectionProjects.module.css";
import ProjectCard from "../ProjectCard/ProjectCard";
import { theProjects } from "./projectsPresentation";
import HomepageSectionHeader from "../HomepageSectionHeader/HomepageSectionHeader";
import { Section, Centered } from "../../general/layout/layout";

export default function SectionProjects() {
  const projects = theProjects("all");
  const titleSpan = "my work";
  const title = "The projects I've been working on";
  const text =
    "I like to stay busy and always have a project in the works. Take a look at some of the applications I've been dedicating my time to.";

  return (
    <Section id={"projects"}>
      <Centered>
        <HomepageSectionHeader titleSpan={titleSpan} title={title} text={text} />
      </Centered>

      <Centered captures className={styles.flexContainer}>
        {projects.map(project => {
          return (
            <ProjectCard
              title={project.title}
              text={project.text}
              url={project.url}
              key={project.title}
              route={project.route}
            />
          );
        })}
      </Centered>
    </Section>
  );
}
