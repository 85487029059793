import styles from "./HomepageSectionHeader.module.css";

export default function HomepageSectionHeader({ titleSpan, title, text }) {
  return (
    <header className={styles.container}>
      <h2>
        <span>{titleSpan}</span>
        {title}
      </h2>
      <p>{text}</p>
    </header>
  );
}
