import styles from "./Header.module.css";
import { H1, H2 } from "../titles/titles";
import HeaderGrid from "../HeaderGrid/HeaderGrid";
import LinkHomePage from "../../general/Link/LinkHomePage";
import { Section, Centered } from "../../general/layout/layout";

export default function Header({ content }) {
  return (
    <Section gradient>
      <Centered>
        <LinkHomePage className={styles.homePageLink} />
        <H1>{content.headerPrimary}</H1>
        <H2>{content.headerSecondary}</H2>
        <HeaderGrid content={content}></HeaderGrid>
      </Centered>
    </Section>
  );
}
