import OpenToTop from "../../../components/project-presentation/OpenToTop/OpenToTop";
import Header from "../../../components/project-presentation/Header/Header";
import WhyBuildProject from "../../../components/project-presentation/WhyBuildProject/WhyBuildProject";
import TextLink from "../../../components/general/Link/TextLink";
import DesignsFlags from "../../../components/project-presentation/DesignsFlags/DesignsFlags";
import ProblemsToughtsFlags from "../../../components/project-presentation/ProblemsThoughtsFlags/ProblemsThoughtsFlags";
import WhatILearned from "../../../components/project-presentation/WhatILearned/WhatILearned";
import Footer from "../../../components/general/Footer/Footer";
import FooterLinksProjects from "../../../components/project-presentation/FooterLinksProjects/FooterLinksProjects";

export default function Flags() {
  const headerContent = {
    headerPrimary: "Flags",
    headerSecondary: "A game to learn to recognize flags and have fun!",
    coreFonctionalities: [
      "Can be enjoyed on desktop, tablet and mobile",
      "Countries data fetched from a RESTful API",
      "Smooth animations with React Transition Group",
    ],
    technologiesUsed: [
      "React",
      "Redux / Redux Toolkit",
      "React Transition Group",
      "CSS Modules",
      "Netlify",
      "Git",
    ],
    codeLink: "https://github.com/jeromeschwaederle/flags",
    siteLink: "https://flags-jerome.netlify.app/",
  };

  const whyBuildProjectContent = [
    {
      title: "My First React Project",
      text: [
        "Having very recently self-taught myself about React and Redux, I wanted to build something from the ground up to challenge my newly acquired knowledge. Mainly in order to keep learning by doing.",
      ],
    },
    {
      title: "Inspired by a game I liked",
      text: [
        "About four years ago, I got pulled in by a game on Google Play where you had to guess flags. The interface was reasonably simple, yet not simplistic and I really enjoyed it.",
      ],
    },
    {
      title: "Making use of a RESTful API",
      text: [
        [
          "In my evergoing journey of learning JavaScript, I encountered some months ago the ",
          <TextLink href={`https://restcountries.com/`}>restcountries</TextLink>,
          " RESTful API. I found it very well made, and wanted to make use of it in a real life project.",
        ],
      ],
    },
    {
      title: "Playing around with Redux",
      text: [
        "Building a game is also a perfect use case to practice and play around with Redux. Making it really straightforward to share the state  to all components of the game.",
      ],
    },
  ];

  const problemsContent = [
    {
      title: "Games MUST have animations",
      text: [
        "I wanted this project to have animations from the start. I really didn't overthink it. And I certainly didn't take into account that when a component unmounts you can't animate some HTML that instantly disapeared from the DOM.",
        "After cursing the front-end gods, I had to find a solution. I came across React Transition Group pretty fast as it is quite mainstream.",
      ],
    },
    {
      title: "Having to learn React Transitions",
      text: [
        [
          "As it was really important for me, I decided to dive into ",
          <TextLink href={"https://reactcommunity.org/react-transition-group/"}>
            React Transition Group
          </TextLink>,
          "'s documentation. Using it with CSS modules didn't make it easier. After playing around with it, it started to make sense. I focused on 'CSSTransition' component for this project.",
        ],
      ],
    },
    {
      title: "The selection algorithm",
      text: [
        "The solution I developped only shows flags that haven't yet been successfully guessed in the four presented possibilies. Which works fine until they are less than four unguessed flags.",
        "At that point, I randomly choose some successfully guessed flags to add and mix them with the 1, 2 or 3 remaining unguessed flags.",
      ],
    },
    {
      title: "The overall game logic",
      text: [
        "I wanted to have levels, 10 of them. But how could I choose which flag is easy or hard to guess? The answer must be subjective as everyone come from a different country.",
        "So I decided to order the countries from most to least populated in 10 groups of 20 countries, which seemed to be rational.",
      ],
    },
  ];

  const whatILearnedContent = [
    "How to animate a component when mounted and unmounted",
    "Deeper understanding of React Hooks",
    "Deeper understanding of state management with Redux",
    "The virtues of code refactorisation",
  ];

  return (
    <OpenToTop>
      <div>
        <Header content={headerContent} />
        <main>
          <WhyBuildProject content={whyBuildProjectContent} />
          <DesignsFlags />
          <ProblemsToughtsFlags content={problemsContent} />
          <WhatILearned content={whatILearnedContent} />
          <FooterLinksProjects projects="passphrase jm" />
        </main>
        <Footer />
      </div>
    </OpenToTop>
  );
}
