import { Section, Centered } from "../../general/layout/layout";
import { H2 } from "../titles/titles";

export default function DesignsSection({ title, children }) {
  return (
    <Section gradient>
      <Centered captures>
        <H2>{title}</H2>
        {children}
      </Centered>
    </Section>
  );
}
