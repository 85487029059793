import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import HomePage from "./routes/HomePage";
import reportWebVitals from "./reportWebVitals";
import Passphrase from "./routes/Projects/Passphrase/Passphrase";
import Jm from "./routes/Projects/Jm/Jm";
import Flags from "./routes/Projects/Flags/Flags";
import Error404 from "./routes/404";

import routes from "./routes/routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<HomePage />} />
        <Route path={routes.passphrase} element={<Passphrase />} />
        <Route path={routes.jm} element={<Jm />} />
        <Route path={routes.flags} element={<Flags />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
