import styles from "./Nav.module.css";
import logo1 from "../../../static/logo/logoWhiteOnBlack.webp";

export default function Nav() {
  const clickHandler = e => {
    e.preventDefault();
    const aElement = e.target.closest("a");
    const elementToScrollTo = document.querySelector(`${aElement.attributes.href.value}`);
    elementToScrollTo?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <nav className={styles.nav}>
      <a onClick={clickHandler} href="#about_me">
        <img src={logo1} alt="mon logo" />
      </a>
      <ul>
        <li className={`${styles.link} ${styles["link--1"]}`}>
          <a onClick={clickHandler} href="#technical_skills">
            <span>Technical Skills</span>
          </a>
        </li>
        <li className={`${styles.link} ${styles["link--2"]}`}>
          <a onClick={clickHandler} href="#projects">
            <span>Projects</span>
          </a>
        </li>
        <li className={`${styles.link} ${styles["link--3"]}`}>
          <a onClick={clickHandler} href="#contact">
            <span>Contact</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}
