import styles from "./CaptureRowWrap.module.css";

export default function CaptureRowWrap({ captures }) {
  return (
    <div className={styles.container}>
      {captures.map((capture, i) => (
        <img key={i} src={capture} alt="a capture of the app" />
      ))}
    </div>
  );
}
