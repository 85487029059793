import Footer from "../../../components/general/Footer/Footer";
import TextLink from "../../../components/general/Link/TextLink";
import OpenToTop from "../../../components/project-presentation/OpenToTop/OpenToTop";
import Header from "../../../components/project-presentation/Header/Header";
import WhatILearned from "../../../components/project-presentation/WhatILearned/WhatILearned";
import WhyBuildProject from "../../../components/project-presentation/WhyBuildProject/WhyBuildProject";
import DesignsPassphrase from "../../../components/project-presentation/DesignsPassphrase/DesignsPassphrase";
import FooterLinksProjects from "../../../components/project-presentation/FooterLinksProjects/FooterLinksProjects";
import ProblemsThoughtsPassphrase from "../../../components/project-presentation/ProblemsThoughtsPassphrase/ProblemsThoughtsPassphrase";

export default function Passphrase() {
  const headerContent = {
    headerPrimary: "Passphrase Generator",
    headerSecondary: "Generate an easy to remember passphrase.",
    coreFonctionalities: [
      "Uses a list of 2048 easy words",
      "Source code can be downloaded/used offline",
      "Passphrase entropy is displayed",
    ],
    technologiesUsed: ["Vanilla JavaScript", "HTML5", "CSS3", "Netlify", "Git"],
    codeLink: "https://github.com/jeromeschwaederle/passphrase",
    siteLink: "https://phrasedepasse.netlify.app/",
  };

  const whyBuildProjectContent = [
    {
      title: "Need of passphrase generator",
      text: [
        "Even if you have a password manager handle passwords for you, you always end up needing a master password / passphrase to secure the password manager. I think an easy to remember random passphrase is the way to go in this case.",
      ],
    },
    {
      title: 'Finding "Diceware"',
      text: [
        [
          "Searching for one online quikly pointed me towards ",
          <TextLink href="https://en.wikipedia.org/wiki/Diceware">diceware</TextLink>,
          " very interesting method. If you are not familiar whith it, here is how it works:",
        ],

        "There is a list of 7776 words, you throw 5 dices, the sequence you obtain gives you the number of the word in the list. Repeat the operation as many times as you want words. Each word acts as a symbol and adds 12.92 bits of entropy.",
      ],
    },
    {
      title: "Thinking of improvements",
      text: [
        "Going through this list, I thought to myself that it was very long and contained a lot of similar words. This represents, in my opinion, two characteristics to be avoided, if one wishes to be able to easily print the list or retain the sentence.",
      ],
    },
    {
      title: "Decision to build my version",
      text: [
        [
          "After a while, I remembered how a ",
          <TextLink href={`https://github.com/bitcoin/bips/blob/master/bip-0039/french.txt`}>
            list of 2048 words
          </TextLink>,
          ' is used to generate a "seedphrase" for bitcoin wallets and decided to hack my        implementation of diceware with this list of words.',
        ],
      ],
    },
  ];

  const ProblemsToughtContent = [
    {
      title: "My main challenge",
      text: [
        "The problem for me was to build a passphrase generator that is really secure in terms of how it generates the passphrase. At first, I thought of the Math.random() method. I wanted to generate a random number between 0 and 2047 to select a word in the list. I checked the docs on MDN and found out this warning above!",
      ],
    },
    {
      title: "Reading the docs",
      text: [
        [
          "So, I checked the newly discovered ",
          <TextLink href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues">
            getRandomValues()
          </TextLink>,
          " MDN page to get acquainted with it. I also read the docs of bitcoin's github repo concerning the list of 2048 words I wanted to use, you can find it ",
          <TextLink href="https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki">
            HERE
          </TextLink>,
          ".",
        ],
      ],
    },
    {
      title: "The solution I came up with",
      text: [
        "To make it short, you need 11 random bits to generate one word. Each word represents an 11 bits binary number.",
        [
          "00000000000 represents the first word",
          <span>
            <br />
          </span>,
          "00000000001 represents the second word",
          <span>
            <br />
          </span>,
          "00000000010 represents the third word",
          <span>
            <br />
          </span>,
          "And so on… 11111111111 represents the last word.",
        ],
      ],
    },
    {
      title: "Implementation details",
      text: [
        "I use window.crypto.getRandomValues() to generate 11 pseudo-random (cryptographically satisfying) values for each word. These values are between -128 and 127 (int8). I then transform these values into 0 (from -128 to -1) or 1 (from 0 to 127) to represent bits.",
        " This gives me 11-bit binary numbers that I just have to turn into decimal numbers. I then transform these decimal numbers into words by retrieving them according to their index in the list.",
      ],
    },
  ];

  const WhatILearnedContent = [
    "I learned about typedArray and how the getRandomValues() method works.",
    "I discovered the information theory, the concept of entropy, and why passwords' and passphrases' strength are mesured in bits.",
    "It was the first time that I implemented a relatively complex JavaScript function by joining together multiple very simple helper functions. It was a very good way of breaking down the overall logic.",
  ];

  return (
    <OpenToTop>
      <div>
        <Header content={headerContent} />
        <main>
          <WhyBuildProject content={whyBuildProjectContent} />
          <DesignsPassphrase />
          <ProblemsThoughtsPassphrase content={ProblemsToughtContent} />
          <WhatILearned content={WhatILearnedContent} />
          <FooterLinksProjects projects="flags jm" />
        </main>
        <Footer />
      </div>
    </OpenToTop>
  );
}
