import OpenToTop from "../../../components/project-presentation/OpenToTop/OpenToTop";
import Header from "../../../components/project-presentation/Header/Header";
import Footer from "../../../components/general/Footer/Footer";
import WhyBuildProject from "../../../components/project-presentation/WhyBuildProject/WhyBuildProject";
import TextLink from "../../../components/general/Link/TextLink";
import DesignsJm from "../../../components/project-presentation/DesignsJm/DesignsJm";
import WhatILearned from "../../../components/project-presentation/WhatILearned/WhatILearned";
import FooterLinksProjects from "../../../components/project-presentation/FooterLinksProjects/FooterLinksProjects";
import ProblemsThoughtsJm from "../../../components/project-presentation/ProblemsThoughtsJm/ProblemsThoughtsJm";

export default function Jm() {
  const headerContent = {
    headerPrimary: "Majority Judgment",
    headerSecondary: "A mobile ballot box",
    coreFonctionalities: [
      "Implements the majority judgment voting system",
      "Results are secured by a password",
      "Mobile first design",
    ],
    technologiesUsed: ["React", "Redux / Redux Toolkit", "CSS Modules", "Netlify", "Git"],
    codeLink: "https://github.com/jeromeschwaederle/jm",
    siteLink: "https://jugement-majoritaire.netlify.app/",
  };

  const whyBuidProjectContent = [
    {
      title: "A way to give majority judgment publicity",
      text: [
        [
          "Not so many people know about the ",
          <TextLink href={"https://en.wikipedia.org/wiki/Majority_judgment"}>
            majority judgment
          </TextLink>,
          " voting system.",
        ],
        "Building a tool using it was for me a way of understanding it. And moreover a way to let other people try and experiment with it.",
      ],
    },
    {
      title: "A deep interest in the underlying subject",
      text: [
        "The question of voting systems and how decision and consensus can be reached in a group of heterogeneous individuals is something that I find fascinating.",
        [
          "So much so that my final project for cs50 was a ",
          <TextLink href={"https://www.youtube.com/watch?v=jCPWqXPRzuU"}>
            python implementation
          </TextLink>,
          " of the majority judgment voting system with a terminal interface.",
        ],
      ],
    },
    {
      title: "Wanting to use it on my phone",
      text: [
        "A terminal interface is definitely not ideal in terms of user experience.",
        "I wanted to make a second version that I could use on my phone. And that anyone could use or test on their phone, tablet or computer whether it is a mac, pc and android platform.",
      ],
    },
    {
      title: "A worthy algoritmic challenge",
      text: [
        "Getting a deep understanding of the tie-breacking rule of the majority judgment system is not trivial.",
        [
          "Hacking a reliable ",
          <TextLink href={"https://github.com/jeromeschwaederle/jm/blob/master/src/store/algo.js"}>
            code
          </TextLink>,
          " version of it is even less so, no matter the langage you use (python or JavaScript).",
        ],
      ],
    },
  ];

  const problemContent = [
    {
      title: "Thinking the whole UI/UX",
      text: [
        "Before I started writing my first line of code, I sat down with a piece of paper and wrote a schema of what the UX/UI should be.",
        "I underestimated the complexity of this exercise, as I wanted to allow the user to confirm forms and/or to go back on certain steps.",
      ],
    },
    {
      title: "Managing what to show and when",
      text: [
        "So I coded the UI logic and distributed it between components, top to bottom. I spent a lot of time thinking about the best way of doing it trougth the component's three either using Redux, useState() or props.",
      ],
    },
    {
      title: "Building generic components",
      text: [
        "I builded generic components as much as I could for this project.",
        "So, in the process of building this app, if I wanted to change or modify something, it always stayed relatively easy and straightforward to do so.",
      ],
    },
    {
      title: "The design challenge",
      text: [
        "I constantly doubt my choices, change something, change back to how it was.",
        "I learn a little bit more with every project but taking design decisions may still be the hardest part for me.",
      ],
    },
  ];

  const whatILearnedContent = [
    "How taking the time to plan at the very start of a project earns a lot of time down the road. I did it for this project but for the next one, I'll do it even more.",
    "This project helped me solidify a lot my React/Redux knowledge",
    "Plenty of great practice using ES6+ syntax",
  ];

  return (
    <OpenToTop>
      <div>
        <Header content={headerContent} />
        <main>
          <WhyBuildProject content={whyBuidProjectContent} />
          <DesignsJm />
          <ProblemsThoughtsJm content={problemContent} />
          <WhatILearned content={whatILearnedContent} />
          <FooterLinksProjects projects="passphrase flags" />
        </main>
        <Footer />
      </div>
    </OpenToTop>
  );
}
