import { Centered, Section } from "../../general/layout/layout";
import { H2 } from "../titles/titles";
import { theProjects } from "../../homepage/SectionProjects/projectsPresentation";
import ProjectCard from "../../homepage/ProjectCard/ProjectCard";
import styles from "./FooterLinksProjects.module.css";
import LinkHomePage from "../../general/Link/LinkHomePage";

export default function FooterLinksProjects({ projects }) {
  const projectsToDisplay = theProjects(`${projects}`);

  return (
    <Section>
      <Centered>
        <H2>My other projects</H2>
      </Centered>
      <Centered className={styles.projectsContainer} captures>
        {projectsToDisplay.map(project => {
          return (
            <ProjectCard
              title={project.title}
              text={project.text}
              url={project.url}
              key={project.title}
              route={project.route}
            />
          );
        })}
      </Centered>
      <div className={styles.linkHomePage}>
        <LinkHomePage />
      </div>
    </Section>
  );
}
