import styles from "./HomePage.module.css";
import Hero from "../components/homepage/Hero/Hero";
import Main from "../components/homepage/Main/Main";
import Footer from "../components/general/Footer/Footer";
import Nav from "../components/general/Nav/Nav";
import OpenToTop from "../components/project-presentation/OpenToTop/OpenToTop";

export default function HomePage() {
  return (
    <OpenToTop>
      <div className={styles.container}>
        <Nav />
        <Hero />
        <Main />
        <Footer />
      </div>
    </OpenToTop>
  );
}
