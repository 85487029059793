import styles from "./Footer.module.css";
import { ReactComponent as GithubIcon } from "../../../static/icons/github.svg";
import { ReactComponent as LinkedinIcon } from "../../../static/icons/linkedin.svg";

export default function Footer() {
  return (
    <footer className={styles.container}>
      <div className={styles.line}></div>
      <ul>
        <li>
          <a
            href="https://github.com/jeromeschwaederle"
            target="_blank"
            rel="noreferrer"
            title="Github"
          >
            <GithubIcon />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/schwaederle/"
            title="Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon />
          </a>
        </li>
      </ul>
    </footer>
  );
}
