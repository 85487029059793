import DesignsSection from "../DesignsSection/DesignsSection";
import { H3 } from "../titles/titles";
import desktop0 from "../../../static/project-captures/jm/desktop0.webp";
import desktop1 from "../../../static/project-captures/jm/desktop1.webp";
import desktop2 from "../../../static/project-captures/jm/desktop2.webp";
import mobile0 from "../../../static/project-captures/jm/mobile0.webp";
import mobile1 from "../../../static/project-captures/jm/mobile1.webp";
import mobile2 from "../../../static/project-captures/jm/mobile2.webp";
import CaptureMobileAndDesktopContainer from "../CaptureMobileAndDesktopContainer/CaptureMobileAndDesktopContainer";
import styles from "./DesignsJm.module.css";

const setUp = { mobile: mobile0, desktop: desktop0 };
const giveMention = { mobile: mobile1, desktop: desktop1 };
const results = { mobile: mobile2, desktop: desktop2 };

export default function DesignsJm() {
  return (
    <DesignsSection title={"The three main steps of the vote"}>
      <div className={styles.steps}>
        <H3>First, set up the vote</H3>
        <CaptureMobileAndDesktopContainer toLeft source={setUp} />
      </div>
      <div className={styles.steps}>
        <H3>Second, each participant gives a mention to each proposition</H3>
        <CaptureMobileAndDesktopContainer toLeft source={giveMention} />
      </div>
      <div className={styles.steps}>
        <H3>Third, check out the results!</H3>
        <CaptureMobileAndDesktopContainer toLeft source={results} />
      </div>
    </DesignsSection>
  );
}
