import styles from "./Hero.module.css";
import photo from "../../../static/photos/photo_jerome2.webp";
import TextGradient from "../../general/TextGradient/TextGradient";
import { Section, Centered } from "../../general/layout/layout";

export default function Hero() {
  return (
    <Section className={styles.container} id="about_me">
      <Centered>
        <div className={styles.titleAndPhotoContainer}>
          <h1 className={styles.title}>
            <span className={`${styles.titleLine} ${styles["titleLine--1"]}`}>
              Hello, I'm Jérôme.
            </span>
            <span className={`${styles.titleLine} ${styles["titleLine--2"]}`}>
              <span>Web</span>
              <TextGradient className={styles.colorText}>developer,</TextGradient>
            </span>
            <span className={`${styles.titleLine} ${styles["titleLine--3"]}`}>
              <span>Perpetual</span>
              <TextGradient className={styles.colorText}>learner,</TextGradient>
            </span>
            <span className={`${styles.titleLine} ${styles["titleLine--4"]}`}>
              <span>Challenge</span>
              <TextGradient className={styles.colorText}>enthousiast!</TextGradient>
            </span>
          </h1>
          <img
            className={styles.photo}
            src={photo}
            alt="Jérôme Schwaederlé"
            title="Jérôme Schwaederlé"
          />
        </div>
        <div className={styles.textContainer}>
          <p className={`${styles.paragraph} ${styles["paragraph--1"]}`}>
            I'm a web developer based in Metz, France. My web-development journey has started last
            year with the incredible computer science course "CS50" from Harvard university.
          </p>
          <p className={`${styles.paragraph} ${styles["paragraph--2"]}`}>
            I have since then been awarded my professional certificate in Computer Science for Web
            Programming by HarvardX.
          </p>

          <p className={`${styles.paragraph} ${styles["paragraph--3"]}`}>
            After hundreds of hours spent with the mentors I learned from, I understand the
            importance of writing code that is easy to grasp and to maintain. And that's often where
            the challenge lies!
          </p>
          <p className={`${styles.paragraph} ${styles["paragraph--4"]}`}>
            And so much is always left to learn, as each project represents an opportunity to
            improve.
          </p>
        </div>
      </Centered>
    </Section>
  );
}
