import SectionSkills from "../SectionSkills/SectionSkills";
import SectionProjects from "../SectionProjects/SectionProjects";
import SectionContact from "../SectionContact/SectionContact";

export default function Main() {
  return (
    <main>
      <SectionSkills />
      <SectionProjects />
      <SectionContact />
    </main>
  );
}
